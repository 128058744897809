@import "~@ionic/angular/css/core.css";

@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

.scanner-ui {
    display: none;
}
.scanner-hide {
    visibility: visible;
}


body.qrscanner {
    background-color: transparent;
}
body.qrscanner .scanner-ui {
    display: block;
}
body.qrscanner .scanner-hide {
    visibility: hidden;
}

ion-button[size="small"]::part(native) {
    font-size: 11px;
}

html {
    -webkit-font-smoothing: antialiased;
}

ion-card {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.05) !important;
    border-radius: 8px !important;
    margin: 16px !important;
    --color: var(--ion-color) !important;
    user-select: text;
    ion-item {
        --border-width: 0;
        --border-color: transparent;
    }
}
ion-segment-button {
    ion-label {
        margin: 0px;
        font-size: 12px;
    }
}


ion-card-title {
    font-size: 28px;
    font-weight: 700;
    line-height: 1.2;
}

ion-card-subtitle {
    text-transform: none;
    font-weight: 400;
    margin-bottom: 0px;
}

ion-label {
    white-space: normal !important;
}

.item-datetime {
    ion-icon {
        font-size: 19px;
        margin: 0px 1px 0px 0px;
        color: #999;
    }
}

ion-item-divider {
    --background: #fff;
    padding-top: 1.5rem;
    font-weight: 500;
    text-transform: uppercase;
}

ol {
    margin: 0px;
    list-style-position: outside;
    padding: 0px 16px;
}

ion-label em {
    color: var(--ion-color-danger);
}

ion-grid {
    --ion-grid-padding: 0px;
}

ion-col {
    --ion-grid-column-padding: 0px;
}

ion-tab-button fa-icon {
    padding-bottom: 3px;
}

ion-tab-bar {
    height: 60px;
}



[required] {
    ion-label {
        font-weight: 500;
        &:after {
            content: "*";
            color: red;
            margin-left: 5px;
        }
    }
}

/**
* Utilities
*/

$width-list: 10 15 25 33 50 75 100;
@each $current-width in $width-list {
    $i: index($width-list, $current-width);
    [w-#{$current-width}] {
        width: percentage($current-width / 100);
    }
}

.mr-3 {
    margin-right: 3rem;
}
.mr-1 {
    margin-right: 1rem;
}
