// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  --ion-color-primary: #DD4830;
  --ion-color-primary-rgb: 221,72,48;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #c23f2a;
  --ion-color-primary-tint: #e05a45;

  --ion-color-secondary: #414085;
  --ion-color-secondary-rgb: 65,64,133;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255,255,255;
  --ion-color-secondary-shade: #393875;
  --ion-color-secondary-tint: #545391;

    --ion-color-tertiary: #ffc833;
    --ion-color-tertiary-rgb: 255, 200, 51;
    --ion-color-tertiary-contrast: #000000;
    --ion-color-tertiary-contrast-rgb: 0, 0, 0;
    --ion-color-tertiary-shade: #e0b02d;
    --ion-color-tertiary-tint: #ffce47;

    --ion-color-success: #4cd9a0;
    --ion-color-success-rgb: 76, 217, 160;
    --ion-color-success-contrast: #fff;
    --ion-color-success-contrast-rgb: 255, 255, 255;
    --ion-color-success-shade: #43bf8d;
    --ion-color-success-tint: #5eddaa;

    --ion-color-warning: #ffc833;
    --ion-color-warning-rgb: 255, 200, 51;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0b02d;
    --ion-color-warning-tint: #ffce47;

    --ion-color-danger: #ff3700;
    --ion-color-danger-rgb: 255, 55, 0;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #e03000;
    --ion-color-danger-tint: #ff4b1a;

    --ion-color-dark: #1d1d1d;
    --ion-color-dark-rgb: 29, 29, 29;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #1a1a1a;
    --ion-color-dark-tint: #343434;

    --ion-color-medium: #92949c;
    --ion-color-medium-rgb: 146, 148, 156;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #808289;
    --ion-color-medium-tint: #9d9fa6;

    --ion-color-light: #f4f5f8;
    --ion-color-light-rgb: 244, 245, 248;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #d7d8da;
    --ion-color-light-tint: #f5f6f9;

    /** white **/
    --ion-color-white: #fff;
    --ion-color-white-rgb: 255, 255, 255;
    --ion-color-white-contrast: #22292f;
    --ion-color-white-contrast-rgb: 0, 0, 0;
    --ion-color-white-shade: #d4d8da;
    --ion-color-white-tint: #f2f6f9;

    /** Custom Ionic **/
    --ion-background-color: var(--ion-color-light);
    --ion-item-background: #fff;
    --ion-item-border-color-rgb: var(--ion-color-dark-rgb);
    --ion-color-shade: var(--ion-color-dark);

    --ion-font-family: "Fira Sans";
}

.ion-color-white {
    --ion-color-base: #fff;
    --ion-color-base-rgb: 255, 255, 255;
    --ion-color-contrast: #000000;
    --ion-color-contrast-rgb: 0, 0, 0;
    --ion-color-shade: #d7d8da;
    --ion-color-tint: #f5f6f9;
}

@import url("https://fonts.googleapis.com/css?family=Fira+Sans:300,400,500,600,700");
